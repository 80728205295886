import React from 'react'
import { graphql } from 'gatsby'
import ContentProduktLeistung from '../components/ContentProduktLeistung'
import Newsletter from '../components/NewsletterPopup.js'

const LeistungTemplate = ({ data }) => {
  return (
    <>
      <Newsletter />
      <ContentProduktLeistung transfer={data.contentfulLeistungen} />
    </>
  )
}

export const query = graphql`
  query($slug: String!) {
    contentfulLeistungen(slug: { eq: $slug }) {
      metaDescription {
        internal {
          content
        }
      }
      farbe
      title
      slug
      intBeschreibung {
        json
      }
      ansprechpartnerBild {
        title
        fluid(maxWidth: 398) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      ansprechpartnerEmail
      ansprechpartnerName
      ansprechpartnerTelefon
      ansprechpartnerZitat {
        internal {
          content
        }
      }
      backgroundImage {
        title
        fluid(maxWidth: 996) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      content {
        json
      }
      kunden
      metaTitle
      contentListe {
        json
      }
      identifier
    }
  }
`

export default LeistungTemplate
